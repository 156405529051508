<template>
  <div class="flex min-h-screen flex-col text-txt bg-bgr">
    <Colors :colors="colors" />
    <Header />
    <slot v-if="reservation" />
    <SomeError v-else type="reservations" />
    <Footer
      :footer-links="navigation?.links"
      class="print:hidden"
      :logo-caption="logoCaption"
    />
  </div>
</template>

<script lang="ts" setup>
const { logoCaption, navigation } = useConfdata()
const { colors } = useColors()
const { reservation } = useReservation()

if (!useReservation().reservation) {
  useHead({ title: 'Token Error' })
}
</script>

<style scoped></style>
